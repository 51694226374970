<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-form autocomplete="on" @submit="recoverpass">
                    <v-card class="elevation-5 pa-3">
                        <div class="text-right">
                            <localization />
                        </div>
                        <v-card-text>
                            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                                {{ result }}
                            </v-snackbar>
                            <div class="layout column align-center">
                                <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                                <h1 class="text-center my-4 primary--text">
                                    Récupération du mot de passe
                                </h1>
                                <div>Veuillez entrer votre email OU votre numéro de téléphone</div>
                            </div>

                            <v-text-field v-model="model.email" append-icon="mdi-at" name="email" :label="$t('login.email')"
                                :rules="[rules.email]" type="email" />
                            <vue-tel-input-vuetify v-model="model.phone" mode="international" placeholder=""
                                label="Téléphone" :preferred-countries="['fr', 'gb']" />
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn type="submit" color="primary" :loading="loading" block>
                                        Valider
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signin" block>
                                        {{ $t('login.signIn') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signup" block>
                                        {{ $t('login.signUp') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'ForgottenPassword',
    components: { Localization },
    data: () => ({
        loading: false,
        showResult: false,
        color: 'undefined',
        result: '',
        alertcolor: 'green',
        rules: {
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Email invalide.';
            },
        },
        model: {
            email: '',
            phone: ''
        },
    }),
    methods: {
        async recoverpass(e) {
            e.preventDefault();
            const reponse = await this.$store.dispatch('RecoverPassword', {
                email: this.model.email,
                phone: this.model.phone,
                config: this.$config,
                axios: this.$axios
            });
            if (reponse.header === 'Succès') {
                this.result = reponse.content;
                this.showResult = true;
                this.alertcolor = 'green';
            } else {
                this.result = "Votre email ou votre téléphone n'est pas dans notre base de données";
                this.showResult = true;
                this.alertcolor = 'orange';
            }
        }
    },
};

</script>
